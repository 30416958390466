<template>
  <div class="w-100 mb-2">
    <table class="table table-sm">
      <tr>
        <th>{{ $t("commands.requirements.cols.col1") }}</th>
        <th>{{ $t("commands.requirements.cols.col2") }}</th>
        <th></th>
      </tr>
      <tr v-for="(field, index) in model" :key="index">
        <template v-if="!hiddenTypes.includes(field.type)">
          <td>
            <b-form-select
              v-model="field.type"
              :options="options"
              size="sm"
            ></b-form-select>
          </td>
          <td>
            <b-form-checkbox
              v-model="field.multiple"
              :value="1"
              :unchecked-value="0"
              class="m-auto w-25"
            />
          </td>
          <td>
            <a href="#" @click.prevent="removeField(index)" class="btn btn-sm btn-danger">
              <feather-icon icon="Trash2Icon" size="14" />
            </a>
          </td>
        </template>
      </tr>
    </table>
    <div class="text-right">
      <a href="#" @click.prevent="addField" class="btn btn-sm btn-primary">
        <feather-icon icon="PlusIcon" size="14" />
      </a>
    </div>
  </div>
</template>

<script>
import fields from "@/libs/CommandFields/fields";

export default {
  props: {
    value: Array | Object,
  },
  data() {
    return {
      // TODO: Translations
      options: [
        { value: "cameras", text: "Cámaras" },
        { value: "units", text: "Instalaciones" },
        { value: "user_views", text: "Vistas/Tabs" },
        { value: "users", text: "Usuarios" },
        { value: "email", text: "E-mail(s)" },
        { value: "phone", text: "Teléfono(s)" },
        { value: "interphone_from", text: "Interfon (origen)" },
        { value: "interphone_to", text: "Interfon (destino)" },
        { value: "do", text: "Digital Output" },
        { value: "message_template", text: "Plantillas de mensajes" },
        { value: "density_device", text: "Dispositivos Density" },
        { value: "type", text: "Tipo de Notificación" },
        { value: "receiver", text: "Receptor" },
        { value: "verb", text: "Verbo RESTful" },
        { value: "authorization", text: "Autorización" },
        { value: "action_plan", text: "Plan de acción" },
        { value: "url1", text: "URL de alarma" },
        { value: "url2", text: "URL de comentarios de alarma" },
        { value: "delay", text: "Retraso" },
        { value: "radius", text: "Radio" },
        { value: "location", text: "Ubicación" },
        { value: "groups", text: "Groups" },
        { value: "tenants", text: "Tenants" },
        { value: "centers", text: "Centers" },
        { value: "sectors", text: "Sectors" },
        { value: "filter", text: "Filter" },
        { value: "layout", text: "Layout" },
        { value: "loop", text: "Bucle" },
      ],
      options: [],
      hiddenTypes: ["alarm"],
    };
  },
  created() {
    const keys = Object.keys(fields);
    this.options = keys.map((k) => ({
      value: fields[k].field_name,
      text: this.$t(fields[k].name),
    }));
  },
  computed: {
    model: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addField() {
      this.value.push({ type: "cameras", multiple: 0 });
    },
    removeField(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>

<style></style>
