<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            `${isAdd ? $t("actions.add") : $t("actions.edit")} ${$tc(
              "commands.title",
              1
            )}`
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->
          <validation-provider #default="validationContext" name="name">
            <b-form-group
              class="text-capitalize"
              :label="$t('command.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group
              class="text-capitalize"
              :label="$t('command.description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- command -->
          <validation-provider #default="validationContext" name="command">
            <b-form-group
              class="text-capitalize"
              :label="$t('command.title')"
              label-for="command"
            >
              <b-form-input
                id="command"
                v-model="itemData.command"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- alarm compatible -->
          <validation-provider rules="required" vid="alarm_compatible">
            <b-form-checkbox
              id="alarm_compatible"
              v-model="itemData.alarm_compatible"
              value="1"
              unchecked-value="0"
              name="alarm_compatible"
              class="my-1 text-capitalize"
              switch
            >
              {{ $t('commands.fields.alarm_compatible.label') }}
            </b-form-checkbox>
          </validation-provider>

          <!-- requirements -->
          <b-form-group
            class="text-capitalize"
            :label="$t('commands.fields.requirements.label')"
            label-for="requirements"
          >
            <requirements-component v-model="itemData.requirements" />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import RequirementsComponent from "./components/RequirementsComponent.vue";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    RequirementsComponent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: "",
      description: "",
      command: "",
      alarm_compatible: false,
      requirements: [],
    };
    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => (itemData.value = JSON.parse(JSON.stringify(blankData)));
    const onSubmit = () => {
      let req = itemData.value;
      let dispatch;

      if (!itemData.value.requirements) {
        itemData.value.requirements = blankData.requirements;
      }

      if (props.isAdd) {
        dispatch = store.dispatch("command/add", req);
      } else {
        const data = {
          id: itemData.value.id,
          params: req,
        };
        dispatch = store.dispatch("command/edit", data);
      }

      dispatch
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-sidebar-active", false);
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``;
            Object.entries(response.response.data.data).forEach((entry) => {
              const [key, value] = entry;
              string += `${key} - ${value}<br>`;
            });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${response.response.data.message}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: string,
                },
              },
              {
                timeout: 10000,
              }
            );
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        itemData.value.requirements = itemData.value.requirements
          ? itemData.value.requirements
          : [];
      }
    };

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>